import { React, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useSelector, useDispatch } from 'react-redux';
import { fetchOnlineSubscriptions, fetchSubscriptions, setDataFetched } from 'controller/redux/HomeSlice';

import LoadingPageComponent from 'render/views/components/LoadingPageComponent';
import SubscriptionComponent from 'render/views/pages/Subscribe/SubscriptionComponent';
import SwipperComponent from 'render/views/components/SwipperComponent';
import { useWindowDimensions } from 'controller/hooks/hooks';
import { HOME_MIN_WIDTH_SLIDER } from 'scripts/Constants';
import OnlineSubscriptionComponent from './OnlineSubscriptionComponent';

function SubscribePage() {

  const dispatch = useDispatch();

  const { subscriptions, online_subscriptions, isDataFetched } = useSelector(
    (state) => state.home
  );

  const [isLoading, setIsLoading] = useState(false);
  const dimensions = useWindowDimensions();
  const [selectedFrequency, setSelectedFrequency] = useState('monthly_option');
  const [selectedLocation, setSelectedLocation] = useState('Carnaxide');

  const handleFrequencyChange = (event) => {
    setSelectedFrequency(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const filteredSubscriptions = subscriptions.filter(subscription => {
    return subscription.recursive && subscription.boxs.includes(selectedLocation);
  });

  const filteredNonRecursiveSubscriptions = subscriptions.filter(subscription => {
    return !subscription.recursive && subscription.boxs.includes(selectedLocation);
  });

  useEffect(() => {
    if (!isDataFetched) {
      setIsLoading(true);
      Promise.all([
        dispatch(fetchSubscriptions()),
        dispatch(fetchOnlineSubscriptions())
      ]).then(() => {
        dispatch(setDataFetched(true));
        setIsLoading(false);
      });

    }
  }, [dispatch, isDataFetched]);

  if (isLoading) {
    return (
      <LoadingPageComponent />
    )
  }

  return (
    <div id="page-container" page="subscribe">
      <Helmet>
        <title>Sthenos | Inscreve-te </title>
        <meta name="description" content="A melhor forma de treinar Calistenia em Portugal" />
        <meta name="keywords" content="carnaxide,box,calistenia,portugal" />
      </Helmet>

      <div className="page-header-image">
        <img alt="" src={`${process.env.REACT_APP_MEDIA_BASE_PATH_STATIC}/auxiliary/sthenos-fit-box-main.jpg`} />
      </div>
      <section className="container p-0">
        <section className="container">
          <h1 className="page-title">Inscreve-te</h1>
        </section>
        <hr className="hr-xl hr-l-on-breakpoint-m" />
        <section className="container-xs">
          <p className="text-l text-primary text-center">
            Agora qualquer altura é boa para começar.
            <br/>
            Começa a treinar a qualquer altura do mês e paga o proporcional ao número de dias!
          </p>

          <hr className="hr-xl hr-l-on-breakpoint-m" />
        </section>

        <section className="container-m" id="section-home-subscriptions">
          <div className="section-header flex-row justify-between gap-m flex-column-on-breakpoint-ml align-center-on-breakpoint-ml gap-0-on-breakpoint-ml">
            <h2 className="section-title light-bg">Junta-te</h2>
            <div className="section-actions flex-row gap-s">
              <div className="custom-select flex-column">
                <label htmlFor="frequency">Pagamento:</label>
                <div>
                  <select className="btn btn-primary-ghost btn-alt" id="frequency" value={selectedFrequency} onChange={handleFrequencyChange}>
                    <option value="monthly_option">Mensal</option>
                    <option value="trimestre_option">Trimestral</option>
                    <option value="semestre_option">Semestral</option>
                    <option value="year_option">Anual</option>
                  </select>
                  <svg className="chevron-down" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ 'enableBackground': 'new 0 0 14 9' }} viewBox="0 0 14 9"><path d="M1.5 2.1 7.1 7l5.4-4.8" /></svg>
                </div>
              </div>

              {/* <div className="custom-select flex-column">
                  <label htmlFor="location">Box:</label>
                  <div>
                    <select className="btn btn-primary-ghost btn-alt" id="location" value={selectedLocation} onChange={handleLocationChange}>
                      <option value="Carnaxide">Carnaxide</option>
                      <option value="Benfica">Benfica</option>
                    </select>
                    <svg className="chevron-down" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{'enableBackground':'new 0 0 14 9'}} viewBox="0 0 14 9"><path d="M1.5 2.1 7.1 7l5.4-4.8"/></svg>
                  </div>
                </div> */}
            </div>
          </div>
          <SwipperComponent slides={filteredSubscriptions} component={SubscriptionComponent} propName="subscription" props={{ frequency: selectedFrequency, box: selectedLocation }} />
          {/* {
              dimensions.width < HOME_MIN_WIDTH_SLIDER ?
                <SwipperComponent slides={filteredSubscriptions} component={SubscriptionComponent} propName="subscription" props={{ frequency: selectedFrequency, box: selectedLocation }} />
                :
                <div className="flex-row justify-center gap-s">
                  {
                    filteredSubscriptions.map((subscription, index) => (
                      <SubscriptionComponent subscription={subscription} frequency={selectedFrequency} box={selectedLocation} key={index} />
                    ))
                  }
                </div>
            } */}

          <hr className="hr-m" />
          <hr className="hr-l vertical-line bg-gray-lighter" />
          <hr className="hr-ml" />
          <div className="container p-0" style={{ 'maxWidth': '850px' }}>
            <h3 className="uppercase text-gray-lighter text-center">Queres só exprimentar? Estamos aqui para ti</h3>
          </div>
          <hr className="hr-xl" />
          {
            dimensions.width < HOME_MIN_WIDTH_SLIDER ?
              <SwipperComponent slides={filteredNonRecursiveSubscriptions} component={SubscriptionComponent} propName="subscription" props={{ box: selectedLocation }} />
              :
              <div className="grid-4 gap-s">
                {
                  filteredNonRecursiveSubscriptions.map((subscription, index) => (
                    <SubscriptionComponent subscription={subscription} box={selectedLocation} key={index} />
                  ))
                }
              </div>
          }

        </section>

        <section className="container-m" style={{ display: 'none' }}>
          <hr className="hr-l" />
          <hr className="hr-xl vertical-line bg-gray-lighter" />
          <hr className="hr-m" />

          <div className="flex-column align-center gap-l text-center">
            <h1 className="section-title text-accent text-outline-thicker">Planos Online</h1>
            <div className="container p-0" style={{ 'maxWidth': '850px' }}>
              <h3 className="uppercase text-gray-lighter">Para seguires ao teu ritmo onde quer que estejas</h3>
            </div>
          </div>

          <hr className="hr-xl" />
          <div className="container-s flex-column justify-center gap-s" id="section-home-online-subscriptions">
            {
              online_subscriptions.map((subscription, index) => (
                <OnlineSubscriptionComponent subscription={subscription} key={index} />
              ))
            }
          </div>
        </section>


        <hr className="hr-xxxl" />
      </section>
    </div>
  );
}

export default SubscribePage